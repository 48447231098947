/* html, body, #root, #root>div { */
html,
body,
#root,
#root {
  height: 100%;
}

html,
body {
  font-weight: 400;
  font-smooth: antialias;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  margin: 0;
}
